import { i18n } from '@/plugins/i18n'
import { EmployeeBasicData } from '@/interfaces/EmployeeBasicData.interface'

/**
 * Prüft ob das angegebene Object ein [[EmployeeBasicData]]-Interface ist.
 *
 * @param obj - Das Objekt bzw. Interfaces welches geprüft werden soll.
 * @returns `true` bei[[EmployeeBasicData]]-Interfaces, sonst `false`
 */
export function isEmployeeBasicData(obj?: object): obj is EmployeeBasicData {
  return (
    !!obj &&
    typeof obj === 'object' &&
    'id' in obj &&
    'inactive' in obj &&
    typeof (obj as EmployeeBasicData).id === 'number' &&
    typeof (obj as EmployeeBasicData).inactive === 'boolean'
  )
}

/**
 * Gibt den Mitarbeitername für das angegebenen Mitarbeiter-Objekt zurück. Für
 * die Zusammensetzung des Mitarbeiternamems wird der Sprachstring
 * `system.employee.format` verwendet.
 *
 * @param employee - Mitarbeiter-Objekt von dem der Mitarbeitername erzeugt
 * werden soll.
 * @param hidePersonnelNumber - Die Personalnummer des Mitarbeiters ausblenden.
 * @returns Erzeugte Mitarbeitername.
 */
export function getEmployeeName(
  employee?: EmployeeBasicData,
  hidePersonnelNumber?: boolean
): string {
  let displayType = 0
  const placeholder = {
    id: 0,
    firstName: '',
    lastName: '',
    personnelNumber: ''
  }

  if (isEmployeeBasicData(employee)) {
    placeholder.id = employee.id

    if (employee.firstName) {
      placeholder.firstName = employee.firstName
      displayType += 1
    }

    if (employee.lastName) {
      placeholder.lastName = employee.lastName
      displayType += 2
    }

    if (hidePersonnelNumber !== true && employee.personnelNumber) {
      placeholder.personnelNumber = employee.personnelNumber
      displayType += 4
    }
  }

  return i18n
    .t(`system.employee.nameFormat[${displayType}]`, placeholder)
    .toString()
}
