
import Dark from '@/mixins/dark'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import validator from 'validator'

/**
 * Color Picker
 */
@Component({
  components: {
    BaseFrame,
    CustomDialog
  }
})
export default class ColorPicker extends Mixins(Dark) {
  /**
   * Indikator, ob der ColorPicker offen oder geschlossen ist.
   */
  public showPicker = false

  /**
   * Label des Feldes.
   */
  @Prop({ type: String, required: false })
  public label?: string

  /**
   * Hinweistext des Feldes.
   */
  @Prop({ type: String, required: false })
  public hint?: string

  /**
   * Text, wenn keine Farbe ausgewählt ist.
   */
  @Prop({ type: String, required: false })
  public noColorText?: string

  /**
   * Wenn `true` wird nur der Farbkreis angezeigt, nicht das Input-Feld.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public buttonOnly!: boolean

  /**
   * Prop der Farbe.
   */
  @Prop({ type: String, required: false, default: '' })
  public value!: string

  /**
   * Die gewählte Farbe als HEX-Wert.
   */
  public selectedColor = ''

  /**
   * Die Farbe, die am Anfang gesetzt war.
   */
  private initalColor = ''

  /**
   * created-Funktion
   *
   * Speichert die übergebene Farbe.
   */
  public created(): void {
    this.selectedColor = this.validatedColor
    this.initalColor = this.selectedColor
    this.$emit('input', this.selectedColor)
  }

  /**
   * Validiert das Prop value und gibt es zurück, sofern es sich um eine
   * Farbe handelt.
   * Wurde kein Prop übermittelt, wird die Standardfarbe zurückgegeben.
   *
   * @returns Farbe als hex-Wert
   */
  private get validatedColor(): string {
    if (!this.value || !validator.isHexColor(this.value)) {
      return ''
    }

    return this.value
  }

  /**
   * Der Anzeigename der Farbe.
   *
   * @returns Ausgabe des Farbnamens
   */
  private get colorName(): string {
    return this.value !== ''
      ? this.value
      : this.noColorText || this.$t('colorPicker.color.missing').toString()
  }

  /**
   * Sendet die neue Farbe an die Elternkomponente, wenn man den Picker wieder
   * schließt.
   */
  @Watch('showPicker')
  private emitColorOnClose(): void {
    if (!this.showPicker) {
      this.$emit('input', this.selectedColor)
    }
  }

  /**
   * Leert die Farbe und emmited diese.
   */
  private clearAndEmit(): void {
    this.selectedColor = this.value === '' ? this.initalColor : ''
    this.$emit('input', this.selectedColor)
  }

  /**
   * Stellt sicher, dass die Farbe kein hex-Wert ist, und kein hexa-Wert.
   */
  private hexIt(): void {
    this.selectedColor = this.selectedColor.slice(0, 7).toUpperCase()
  }
}
