
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { EmployeeBasicData } from '@/interfaces/EmployeeBasicData.interface'
import { Filter } from '@/interfaces/Filter.interface'
import { getService } from '@/helpers/feathers'
import { isEmployeeBasicData, getEmployeeName } from '@/helpers/employee'
import SearchField from '@/components/SearchField/SearchField.vue'

/**
 * Alle Picker-Windows die unterstützt werden in der richtigen Reihenfolge, wie
 * diese durch den Zurück- Button durchgeschaltet werden können.
 */
const ALLOW_PICKER_CONTENT: ('overview' | 'filter' | 'tree')[] = [
  'overview',
  'filter',
  'tree'
]

/**
 * Liste mit den unterstützten Bäumen der Filterung die im Window `tree`
 * ausgegeben werden können.
 */
const ALLOW_TREE_TYPE: string[] = ['departments', 'locations', 'properties']

/**
 * Suchfeld für die globale Suche.
 */
@Component({
  components: {
    SearchField
  }
})
export default class EmployeePicker extends Vue {
  /**
   * Liste aller Mitarbeiter, die ausgewält sind.
   */
  @Prop({ type: [Object, Array], required: false })
  public value?: EmployeeBasicData | EmployeeBasicData[]

  /**
   * Deaktiviert den kompletten Mitarbeiter-Picker.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public disabled!: boolean

  /**
   * Den Button für das Leeren der bereits ausgewählten Mitarbeiter anzeigen.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public clearable!: boolean

  /**
   * Es können mehrere Mitarbeiter ausgewählt werden.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public multiple!: boolean

  /**
   * Ob der Dialog des Mitarbeiter-Pickers geöffnet ist.
   */
  public dialogOpen = false

  /**
   * Farbe des Selected-Buttons vom Mitarbeiter-Picker.
   */
  @Prop({ type: String, required: false, default: 'primary' })
  public buttonColor!: string

  /**
   * Der Sprachschlüssel, der für die Beschriftung des Selected-Buttons des
   * Mitarbeiter-Pickers verwendet wird. Ist kein Sprachschlüssel angegeben,
   * wird je nach Modus die nachfolgenden Schlüssel verwendet:
   * - Mehrfachauswahl: `employeePicker.buttonLabel.selected.multiple`
   * - Einzelauswahl: `employeePicker.buttonLabel.selected.single`
   *
   * Unterstützte Variablen im Sprachschlüssel:
   * - count: Anzahl der ausgewählten Mitarbeiter
   * - entry: String mit den Informationen des ersten ausgewählten Mitarbeiters
   *
   * Der angegebene Schlüssel muss Pluralisierung unterstützen, hierbei die
   * Variante mit den drei Möglichkeiten.
   */
  @Prop({ type: String, required: false, default: '' })
  public buttonLangKey!: string

  /**
   * Icon der im Select-Button des Mitarbeiter-Pickers angezeigt wird.
   */
  @Prop({ type: String, required: false, default: 'mdi-account-multiple' })
  public buttonIcon!: string

  /**
   * Das Window welches im Picker-Dialog angezeigt werden soll. Also ob die
   * Übersicht, Filter oder Baumansicht angezeigt wird.
   */
  public pickerContent: 'overview' | 'filter' | 'tree' = 'overview'

  /**
   * Der Baum, welcher im Window `tree` angezeigt wird.
   */
  public treeType = ''

  /**
   * Offset für den Request der Mitarbeiter
   */
  public paginationOffset = 0

  /**
   * Limit für die Mitarbeiter die auf einer Seite der Pagination stehen sollen.
   */
  public paginationLimit = 20

  /**
   * Maximalanzahl der Seiten die über die Pagination erreichbar sind.
   */
  public paginationTotal = 0

  /**
   * Aktuelle Seiten die über die Pagination geöffnet ist.
   */
  public paginationPage = 1

  /**
   * Ob gerade Mitarbeiter nachgeladen werden.
   */
  public isLoading = false

  /**
   * Liste aller Mitarbeiter, die gerade vom Service geladen worden sind und in
   * der Auflistung angezeigt werden.
   */
  public loadedEmployees: EmployeeBasicData[] = []

  /**
   * Liste aller Mitarbeiter, die der Benutzer ausgewählt aber noch nicht
   * bestätigt hat.
   */
  public selectedEmployees: EmployeeBasicData[] = []

  /**
   * Liste aller Mitarbeiter, die ausgewählt sind. Ist eine gesäuberte Variante
   * von [[this.value]].
   */
  public employees: EmployeeBasicData[] = []

  /**
   * Der Suchstring, der beim Laden der anzuzeigenden Mitarbeitern mit gesendet
   * wird.
   */
  public searchText = ''

  /**
   * Ob der Filter-Button nicht angezeigt werden soll.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public hideFilterButton!: boolean

  /**
   * Die aktiven Filter. Diese werden beim Mitarbeiter-Request mitgegeben.
   */
  public activeFilter: Filter[] = []

  /**
   * Die ausgewählten Filter. Diese werden vom Benutzer in der Filter-Window
   * bearbeitet. Sie werden durch die [[this.onSubmit]]-Methode nach
   * [[this.activeFilter]] kopiert.
   */
  public selectFilter: Filter[] = []

  /**
   * Gibt zurück, ob der Button zum Leeren der ausgewählten Mitarbeiter
   * angezeigt werden kann.
   *
   * @returns Ob Button angezeigt werden soll.
   */
  public get showClearButton(): boolean {
    return this.clearable && this.employees.length !== 0
  }

  /**
   * Gibt die Beschriftung des Buttons für das Öffnen des Pickers zurück.
   *
   * @returns Beschriftung des Button.
   */
  public get selectedButtonLabel(): string {
    const selectedCount = this.employees.length
    const langKey = this.buttonLangKey.trim()
    const employeeText =
      selectedCount !== 0 ? getEmployeeName(this.employees[0]) : ''

    return this.$tc(
      !langKey
        ? `employeePicker.buttonLabel.selected.${
            this.multiple ? 'multiple' : 'single'
          }`
        : langKey,
      selectedCount,
      { count: selectedCount, entry: employeeText }
    )
  }

  /**
   * Gibt die Beschriftung für den Select-Button zurück. Dieser ist von der
   * Eigenschaft [[this.pickerContent]] abhängig.
   *
   * @returns Beschriftung Select-Button.
   */
  public get selectButtonLabel(): string {
    let label: string

    if (this.pickerContent === 'filter') {
      label = this.$t('system.general.finished').toString()
    } else if (this.pickerContent === 'tree') {
      label = this.$t('system.general.finished').toString()
    } else {
      const countEmployees = this.selectedEmployees.length
      label = this.$tc(
        'employeePicker.buttonLabel.select',
        countEmployees || 1,
        { count: countEmployees }
      )
    }

    return label
  }

  /**
   * Es wird eine Liste mit allen IDs von Mitarbeitern zurück gegeben, die in
   * der Liste [[this.loadedEmployees]] enthalten sind und ausgewählt wurden.
   * Ausgewählt sind alle Mitarbeiter, aus der Liste [[this.selectedEmployees]].
   *
   * @returns Liste mit den IDs von Mitarbeiter, die ausgewählt und angezeigt
   * werden.
   */
  public get selectedEmployeeValue(): number[] | number {
    const selectedEmployee: number[] = []

    for (const selected of this.selectedEmployees) {
      const index = this.loadedEmployees.findIndex(
        (employee): boolean => employee.id === selected.id
      )

      if (index !== -1) {
        selectedEmployee.push(selected.id)
      }
    }

    return this.multiple ? selectedEmployee : selectedEmployee[0]
  }

  /**
   * Übernimmt die Änderungen an den ausgewählten Mitarbeitern. Es wird die
   * Liste [[this.selectedEmployees]] entsprechend aktualisiert.
   *
   * @param value - Veränderte Liste mit Mitarbeiter-IDs, die Ausgewählt sind.
   */
  public set selectedEmployeeValue(value: number[] | number) {
    if (this.multiple) {
      const selectedEmployees = []
      const changedEntries: number[] = Array.isArray(value)
        ? value.slice(0)
        : [value]

      // bereits ausgewählte Mitarbeiter behalten, die nicht abgewählt sind.
      for (const selected of this.selectedEmployees) {
        const index = this.loadedEmployees.findIndex(
          (item): boolean => item.id === selected.id
        )

        if (index === -1) {
          selectedEmployees.push(selected)
        } else {
          const missingIndex = changedEntries.indexOf(selected.id)

          if (missingIndex !== -1) {
            changedEntries.splice(missingIndex, 1)
            selectedEmployees.push(selected)
          }
        }
      }

      // neu ausgewählte Mitarbeiter übernehmen
      for (const selectedID of changedEntries) {
        const index = this.loadedEmployees.findIndex(
          (item): boolean => item.id === selectedID
        )

        if (index !== -1) {
          selectedEmployees.push(this.loadedEmployees[index])
        }
      }

      this.selectedEmployees = selectedEmployees
    } else {
      const selectedID = Array.isArray(value)
        ? value.length !== 0
          ? value[0]
          : 0
        : value

      const index: number = this.loadedEmployees.findIndex(
        (x): boolean => x.id === selectedID
      )

      this.selectedEmployees = index > -1 ? [this.loadedEmployees[index]] : []
    }
  }

  /**
   * Vue-Hook `created`, es werden die vorausgewälten Mitarbeitern ausgelesen.
   */
  public created(): void {
    this.readPropertyValue()
  }

  /**
   * Liest die vorausgewählten Mitarbeiter aus der Property [[this.value]] aus
   * und prüft, ob es sich bei den angegebenen Objekten bzw. Einträge wirklich
   * um [[EmployeeBasicData]]-Interfaces handelt. Das Ergebnis der Prüfung wird
   * in der Eigenschaft [[this.employees]] gespeichert.
   */
  public readPropertyValue(): void {
    const value = this.value

    if (Array.isArray(value)) {
      const clenupEntries = value.filter(isEmployeeBasicData)
      this.employees = !this.multiple
        ? clenupEntries.slice(0, 1)
        : clenupEntries
    } else if (value && isEmployeeBasicData(value)) {
      this.employees = [value]
    } else {
      this.employees = []
    }

    // Wenn der Dialog geöffnet ist, die neue Mitarbeiter-Liste in die
    // Eigenschaft [[this.selectedEmployees]] übernehmen.
    if (this.dialogOpen) {
      this.selectedEmployees = this.employees.slice(0)
    }
  }

  /**
   * Wechselt den Inhalt des Pickers, auf die angegebene Seite. Zusätzlich kann
   * `back` verwendet werden, um auf die vorherige Ebene zurückzuspringen.
   *
   * @param page - Picker-Inhalt der angezeigt werden soll.
   * @param tree -Typ des Baums welcher in der Baumansicht angezeigt wird.
   */
  public setPickerContent(
    page: 'overview' | 'filter' | 'tree' | 'back',
    tree?: string
  ): void {
    // `back`-Wert abfangen und Auswerten
    if (page === 'back') {
      const idx = ALLOW_PICKER_CONTENT.indexOf(this.pickerContent) - 1
      page = ALLOW_PICKER_CONTENT[idx > 0 ? idx : 0]
    }

    if (ALLOW_PICKER_CONTENT.indexOf(page) === -1) {
      throw new Error(
        `setPickerContent('${page}'): first argument is invalid` +
          `- only allow: '${ALLOW_PICKER_CONTENT.join(`', '`)}'`
      )
    }

    if (this.hideFilterButton && page !== 'overview') {
      page = 'overview'
    } else if (page === 'overview') {
      // ausgewählte Filter mit den aktiven Filtern überschreiben
      this.selectFilter = JSON.parse(JSON.stringify(this.activeFilter))
    }

    if (page !== 'tree' || (tree && ALLOW_TREE_TYPE.indexOf(tree) !== -1)) {
      this.pickerContent = page
      this.treeType = page !== 'tree' || !tree ? '' : tree
    } else {
      throw new Error(
        `setPickerContent('tree', '${tree}'): second argument is invalid` +
          `- only allow: '${ALLOW_TREE_TYPE.join(`', '`)}'`
      )
    }
  }

  /**
   * Lädt die Mitarbeiterdaten, die im Picker angezeigt werden sollen.
   */
  public async reloadEmployees(): Promise<void> {
    if (this.dialogOpen) {
      this.isLoading = true

      const service = await getService<EmployeeBasicData>('picker-employees')
      const queryForFind = {
        $offset: this.paginationOffset,
        $limit: this.paginationLimit,
        $search: this.searchText.trim(),
        $orderBy: 'username',
        $orderDirection: 'asc',
        $filters: this.activeFilter
      }

      service
        .find({ query: queryForFind })
        .then(
          (result): EmployeeBasicData[] => {
            if (
              !('data' in result) ||
              result.skip !== this.paginationOffset ||
              result.limit !== this.paginationLimit
            ) {
              throw new Error(
                'invalid response or outdated request from employee picker'
              )
            }

            if (this.paginationTotal === 0) {
              this.paginationTotal = Math.ceil(result.total / result.limit)
            }

            return Array.isArray(result.data) ? result.data : []
          },
          (): EmployeeBasicData[] => []
        )
        .then(
          (employees): void => {
            this.loadedEmployees = employees
            this.isLoading = false
          },
          (): void => {
            this.isLoading = false
          }
        )
    }
  }

  /**
   * Setzt die Informationen der Pagination zurück. Dadurch wird diese bei dem
   * nächsten Request für die Mitarbeiter neu berechnet.
   */
  public resetPagination(): void {
    this.paginationTotal = 0
    this.paginationOffset = 0
    this.paginationPage = 1
  }

  /**
   * Setzt die ungespeicherte Auswahl der Mitarbeiter zurück.
   */
  public onClearable(): void {
    this.selectedEmployees = []
    this.onSubmit()
  }

  /**
   * Übernimmt je nach aktuellen Dialog-Window die ausgewählten Mitarbeiter,
   * Filtereinstellungen oder ausgewählte Baumeinträge in die Filter.
   */
  public onSubmit(): void {
    if (this.pickerContent !== 'overview') {
      if (this.pickerContent === 'filter') {
        // [[selectFilter]] nach [[activeFilter]] kopieren, aber als Deep-Copy
        this.activeFilter = JSON.parse(JSON.stringify(this.selectFilter))

        // Pagination zurücksetzen und Mitarbeiter mit den neuen Filtern laden
        this.resetPagination()
        this.reloadEmployees()
      } else if (this.pickerContent === 'tree') {
        // Speicherung der ausgewählten Einträge aus dem Baum.
      }

      // Eine Ebene zurückspringen
      this.setPickerContent('back')
    } else {
      if (this.multiple) {
        this.employees = this.selectedEmployees.slice(0)
        this.$emit('input', this.employees)
      } else if (this.selectedEmployees.length) {
        this.employees = this.selectedEmployees.slice(0, 1)
        this.$emit('input', this.employees[0])
      } else {
        this.employees = []
        this.$emit('input')
      }

      // Dialog schließen, da Mitarbeiter ausgewählt.
      this.dialogOpen = false
    }
  }

  /**
   * Lädt die Mitarbeiter für die ausgewählte Unterseite. Der Offset wird durch
   * die angegebene Seite berechnet.
   *
   * @param page - Seite die geladen werden soll.
   */
  @Watch('paginationPage')
  public watcherPaginationPage(page: number): void {
    if (this.paginationTotal > 0) {
      if (page > this.paginationTotal) {
        page = this.paginationTotal
      }

      if (page < 1) {
        page = 1
      }

      this.paginationOffset = (page - 1) * this.paginationLimit
      this.reloadEmployees()
    }
  }

  /**
   * Verursacht das Neuladen der Mitarbeiter, wenn der Suchtext verändert wird.
   */
  @Watch('searchText')
  public watcherSearchText(): void {
    this.resetPagination()
    this.reloadEmployees()
  }

  /**
   * Aktualisiert den Mitarbeiter-Picker bzw. setzt diesen Zurück, wenn der
   * Dialog des Pickers geöffnet oder geschlossen wird.
   *
   * @param isOpen - Ob Picker geöffnet oder geschlossen wird.
   */
  @Watch('dialogOpen')
  public watcherDialogOpen(isOpen: boolean): void {
    if (isOpen !== false) {
      this.setPickerContent('overview')
      this.selectedEmployees = this.employees.slice(0)
      this.watcherPaginationPage(1)
      this.reloadEmployees()
    } else {
      this.resetPagination()
      this.searchText = ''
      this.loadedEmployees = []
      this.selectedEmployees = []
    }
  }

  /**
   * Änderungen der ausgewählten Mitarbeiter übernehmen.
   */
  @Watch('value')
  public watcherValue(): void {
    this.readPropertyValue()
  }
}
