
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'

/**
 * Suchfeld mit Autocomplete-Funktion
 */
@Component({})
export default class AutocompleteSearchField extends Vue {
  /**
   * Das Icon das vor dem Suchfeld angezeigt wird
   */
  @Prop({ type: String, required: false, default: 'mdi-magnify' })
  public prependIcon!: string

  /**
   * Gibt an, ob noch [[`autocompleteItems`]] geladen werden oder nicht
   */
  @Prop({ type: Boolean, required: false, default: false })
  public loading!: boolean

  /**
   * Items die in dem Suchfeld als Vorschläge und zum Suchen benutzt werden
   * sollen
   */
  @Prop({ type: Array, required: false })
  public autocompleteItems?: (string | object)[]

  /**
   * Label für das Suchfeld
   */
  @Prop({ type: String, required: false, default: 'system.general.search' })
  public label!: string

  /**
   * Gibt an, was aus dem [[`autocompleteItems`]] als Suchtext und Textanzeige
   * genutzt werden soll. Bei einem Objekt kann hier zum Beispiel der Key als
   * String eingesetzt werden.
   */
  @Prop({ type: String || Array || Function, required: false, default: 'text' })
  public itemTextKey!: string | [] | Function

  /**
   * Gibt an, was aus dem [[`autocompleteItems`]] als Key genutzt werden soll.
   * Bei einem Objekt kann hier zum Beispiel der Key als String eingesetzt
   * werden.
   */
  @Prop({ type: String || Array || Function, required: false, default: 'id' })
  public itemValueKey!: string | [] | Function

  /**
   * Gibt an, ob der Item-Chache verwendet werden soll
   */
  @Prop({ type: Boolean, required: false, default: false })
  public itemCache!: boolean

  /**
   * Gibt an, ob die Mehrfachauswahl genutzt werden soll
   */
  @Prop({ type: Boolean, required: false, default: false })
  public selectMultiple!: boolean

  /**
   * Vorausgewählte Items die mit den ausgewählten Items synchronisiert werden
   */
  @PropSync('preselectedItems', { type: [Array, Object] })
  public syncedItems!: (string | object)[] | object

  /**
   * Suchfeldeingabe
   */
  public search: string | null = null

  /**
   * Die ID des letzten gestarteten Timeouts.
   */
  public timeoutID?: number

  /**
   * Gibt an, ob die Komponente fokusiert ist
   */
  public focus = false

  /**
   * CSS-Klassen für die Komponente
   */
  public classes = ''

  /**
   * Reagiert auf Änderungen des Suchfeldinhalts und reicht neue Einträge
   * an die Parent-Komponente weiter.
   *
   * An dieser Stelle wurde das `$emit()` nicht mit `@Emit()` ersetzt, da der
   * Rückgabewert ansonsten durch die Parameter und den Return-Wert zweimal
   * weitergereicht wurden, oder ein Eslint-Fehler ensteht, wenn man nur den
   * Parameter angibt ohne diesen zu nutzen (was an dieser Stelle nicht not-
   * wendig ist)
   *
   * @param newValue - Wert des Suchfeldes
   */
  @Watch('search')
  public watchSearch(newValue: string): void {
    this.clearTimeout()
    this.timeoutID = window.setTimeout((): void => {
      this.$emit('update:search-input', newValue)
    }, 550)
  }

  /**
   * Setzt anhand des [[this.focus]] eine CSS-Klasse in der Multi-Auswahl und
   * leer die Suche bei Fokusverlust, um das Problem mit dem falsch platziertem
   * Label zu beheben.
   */
  @Watch('focus')
  public setLabelTransformation(): void {
    if (this.focus === false) {
      this.search = null
    }

    if (this.selectMultiple && !this.focus && !this.search) {
      this.classes = 'untransformLabel'
    } else if (this.selectMultiple) {
      this.classes = 'transformLabel'
    }
  }

  /**
   * Setzt beim Laden der Seite die CSS-Klasse für das Label wenn vorausgewählte
   * Items im Multiselect vorhanden sind.
   */
  public created(): void {
    if (this.syncedItems && this.selectMultiple) {
      this.classes = 'untransformLabel'
    }
  }

  /**
   * Gibt bei einer Änderung die [[`autocompleteItems`]] zurück oder wenn diese nicht
   * vorhanden sind ein leeres Array
   *
   * @returns leeres Array oder [[`autocompleteItems`]]
   */
  public get allItems(): (string | object)[] {
    if (
      Array.isArray(this.autocompleteItems) &&
      this.autocompleteItems.length
    ) {
      return this.autocompleteItems
    }

    return []
  }

  /**
   * Gibt je nach Wert der Properties an, ob das Suchfeld leerbar sein soll oder
   * nicht
   *
   * @returns string - Gibt an, ob das Feld leerbar ist
   */
  public get isClearable(): string {
    if (!this.search || !this.selectMultiple) {
      return ''
    }
    return 'mdi-close'
  }

  /**
   * Prüft ob noch ein aktiver Timeout vorhanden ist. Ist dies der Fall,
   * wird der vorhandene Timeout beendet und seine ID gelöscht.
   */
  public clearTimeout(): void {
    if (this.timeoutID) {
      window.clearTimeout(this.timeoutID)
      delete this.timeoutID
    }
  }

  /**
   * Leert den Suchfeldeintrag
   */
  public clearSearch(): void {
    this.search = ''
  }
}
