
import { Component, Vue, Watch } from 'vue-property-decorator'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import EmployeePicker from '@/components/EmployeePicker/EmployeePicker.vue'
import { NotificationPayload } from '@/store/notifications/notification-payload.interface'
import { BaseDataset } from '@/store/notifications/data-base-set.interface'
import { namespace } from 'vuex-class'
import { getApp, getService } from '@/helpers/feathers'
import { Connection } from '../../offline/connection.enum'
import AutocompleteSearchField from '@/components/AutocompleteSearchField/AutocompleteSearchField.vue'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'
import ImageCard from '@/components/ImageCard/ImageCard.vue'
import ExternalLogin from '@/components/ExternalLogin/ExternalLogin.vue'
import ImagePicker from '@/components/ImagePicker/ImagePicker.vue'
import { ImageItem } from '@/interfaces/ImageItem.interface'
import GenericError from '@/components/GenericError/GenericError.vue'
import ColorPicker from '../../components/ColorPicker/ColorPicker.vue'
import IconPicker from '@/components/IconPicker/IconPicker.vue'

const NotificationStore = namespace('notification')
const ConnectivityStore = namespace('connectivity')

/**
 * Dashboard-View.
 */
@Component({
  components: {
    CustomDialog,
    EmployeePicker,
    AutocompleteSearchField,
    BaseFrame,
    ImageCard,
    ExternalLogin,
    ImagePicker,
    GenericError,
    ColorPicker,
    IconPicker
  }
})
export default class Spielwiese extends Vue {
  /**
   * online
   */
  @ConnectivityStore.Getter('online')
  public online!: boolean

  /**
   * offline
   */
  @ConnectivityStore.Getter('offline')
  public offline!: boolean

  /**
   *
   */
  public promptResult = false

  /**
   *
   */
  public logo: string = require('@/assets/logo.svg')

  /**
   * Request-Tests: Skip
   */
  public requestSkip = 0

  /**
   * Request-Tests: Limit
   */
  public requestLimit = 10

  /**
   * Request-Tests: Addresse des Services
   */
  public requestPath = 'employees'

  /**
   * Request-Tests: Verbindung (Online, Offfline, Auto)
   */
  public requestConnection: Connection = Connection.Auto

  /**
   * Request-Tests: Methode (get, fing)
   */
  public requestMethod = 'find'

  /**
   * Request-Tests: Ergebniss
   */
  public requestResult = ''

  /**
   * Liste mit Mitarbeitern und ungültige Objekte, für die Vorauswahl.
   */
  public selectedEmployee: object[] = [
    {
      id: 7,
      inactive: false,
      firstName: 'Stefan',
      lastName: 'Etteldorf',
      personnelNumber: '1002',
      email: ''
    },
    { invalid: true },
    {
      id: 23,
      inactive: false,
      firstName: 'Andreas',
      lastName: 'Axmann',
      personnelNumber: '1016',
      email: 'k.medenbach@bloecher.net'
    },
    { invalid: true },
    {
      id: 32,
      inactive: false,
      firstName: 'Michael',
      lastName: 'Gülck',
      personnelNumber: '1088',
      email: 'k.medenbach@bloecher.net'
    }
  ]

  /**
   * Items für den Autocompleter
   */
  public searchItems: object[] = []

  /**
   * Togglet einen Toaster und zeigt diesen an
   */
  @NotificationStore.Action('toggleToast')
  public toggleToast!: (payload: NotificationPayload) => void

  /**
   * Event um auf die Anzeige eines Toasters zu reagieren
   */
  @NotificationStore.State('btnReturn')
  public btnReturn!: boolean

  /**
   * Daten auf die sich der Toaster bezieht
   */
  @NotificationStore.State('data')
  public data!: BaseDataset<object>

  /**
   * Wird beim laden der Seite ausgeführt
   */
  public created(): void {
    this.getEmployees()
  }

  /**
   * Toggle den Toaster
   */
  public showAlert(): void {
    const payload: NotificationPayload = {
      type: 'success',
      btnText: '',
      callback: this.triggerFunc
    }
    this.toggleToast(payload)
  }

  /**
   * Toggle den Toaster in rot
   */
  public showAlert1(): void {
    const payload: NotificationPayload = {
      text: 'Alles gelöycyxcscht',
      type: 'error',
      btnText: 'Nice',
      data: {}
    }
    this.toggleToast(payload)
  }

  /**
   * Toggle den Toaster in blau
   */
  public showAlert2(): void {
    const payload: NotificationPayload = {
      type: 'info',
      btnText: 'Haha',
      data: {}
    }
    this.toggleToast(payload)
  }

  /**
   * Toggle den Toaster in gelb
   */
  public showAlert3(): void {
    const payload: NotificationPayload = {
      text: 'Alles gelöycyxcscht',
      type: 'warning',
      data: { id: 1, name: 'drölf' }
    }
    this.toggleToast(payload)
  }

  /**
   * Testfunktion, die als Callback übergeben werden kann
   */
  public triggerFunc(): void {
    const text = 'Trigger erfolgreich ausgeführt'
    // alert() in der Spielwiese erlauben.
    // eslint-disable-next-line no-alert
    alert(text)
  }

  /**
   * Request-Tests: Sended an den angegebenen Service per Get- oder Find-Methode
   * ein Request und gibt diesen aus. Zum Entwickeln und testen von Hooks!
   */
  public async triggerRequestSend(): Promise<void> {
    const service = await getService(this.requestPath, this.requestConnection)

    if (this.requestMethod !== 'get') {
      service
        .find({
          query: {
            $limit: this.requestLimit,
            $offset: this.requestSkip
          }
        })
        .then(
          (x): void => {
            this.requestResult = JSON.stringify(x, null, `\t`)
          },
          (x): void => {
            this.requestResult = x.toString()
            // eslint-disable-next-line no-console
            console.error(x)
          }
        )
    } else {
      service.get(this.requestSkip).then(
        (x): void => {
          this.requestResult = JSON.stringify(x, null, `\t`)
        },
        (x): void => {
          this.requestResult = x.toString()
          // eslint-disable-next-line no-console
          console.error(x)
        }
      )
    }
  }

  /** Wert des Suchfelds aus dem AutocompleteSearchField */
  public searchFieldValue = null

  /**
   * Ausgewählter Wert aus dem AutocompleteSearchField
   */
  public selectedItem = [
    {
      email: null,
      firstName: '?XD',
      id: 1,
      inactive: false,
      lastName: 'Administrator',
      personnelNumber: 'U123456789',
      username: 'Administrator'
    }
  ]

  /**
   * Ausgewählter Wert aus dem AutocompleteSearchField
   */
  public selectedItem2 = {}

  /** Gibt an ob grade Daten für das AutocompleteSearchField geladen werden */
  public isLoading = false

  /**
   * Holt Daten aus dem Service [[`picker-employee` ]] welche als Test-Daten
   */
  public async getEmployees(): Promise<void> {
    try {
      this.searchItems = await (await getApp())
        .service('picker-employees')
        .find()
    } catch (error) {
      throw error
    }
  }

  /**
   * Gibt setzt den Ladestatus im AutocompleteSearchField auf true für
   * 5 Sekunden
   */
  @Watch('searchFieldValue')
  public getSearchValue(): void {
    this.isLoading = true

    setTimeout((): void => {
      this.isLoading = false
    }, 5000)
  }

  /**
   * Watcher um zu schauen, ob eine Notifikation angezeigt wird, hierdurch und
   * durch das Feld 'data' kann auf den Toaster reagiert werden
   *
   * @param value - aktueller wert
   * @param oldValue - alter Wert
   */
  @Watch('btnReturn')
  private handleStuff(value: boolean, oldValue: boolean): void {
    if (value && this.data.id) {
      // eslint-disable-next-line no-console
      console.log(this.data.id, oldValue)
    }
  }

  /**
   * Watcher um durch Limit die Skip-Anzahl anzupassen
   *
   * @param value - aktueller wert
   * @param oldValue - alter Wert
   */
  @Watch('requestLimit')
  private watchRequestLimit(value: number, oldValue: number): void {
    let buffer = this.requestSkip / oldValue
    buffer = Math.floor(buffer * value)
    this.requestSkip = buffer > 0 ? buffer : 0
  }

  /**
   * Testfunktion zum Anlegen eines Widgets
   */
  public async testFunction(): Promise<void> {
    const test = await (
      await getService('dashboard-widget')
    ).create({
      rowID: 2,
      title: 'Test Widget',
      widgetType: 1,
      size: 1,
      defaultSortOrderAsc: true
    })
    console.log(test)

    console.log('Mein cooler Coverage Test (Spielwiese)')
  }

  /**
   * Dummy Click-Handler für die ImageCard-Beispiele.
   *
   * @param name - Name der ImageCard.
   */
  public onImageCardClick(name: string): void {
    // eslint-disable-next-line no-alert
    alert(`You clicked ${name} :O ${name} is very happy now!`)
  }

  /**
   * Beispielwert für den ImagePicker.
   */
  public imagePickerValue = ''

  /**
   * Beispieldaten für den ImagePicker.
   */
  public imagePickerItems: ImageItem[] = [
    {
      label: 'Birdo 1',
      src: 'https://placekitten.com/100/100',
      value: 'value_1'
    },
    {
      label: 'Birdo 2',
      src: 'https://placekitten.com/200/200',
      value: 'value_2'
    },
    {
      label: 'Birdo 3',
      src: 'https://placekitten.com/300/300',
      value: 'value_3'
    },
    {
      label: 'Birdo 4',
      src: 'https://placekitten.com/400/400',
      value: 'value_4'
    },
    {
      label: 'Birdo 5',
      src: 'https://placekitten.com/500/500',
      value: 'value_5'
    },
    {
      label: 'Birdo 6',
      src: 'https://placekitten.com/600/600',
      value: 'value_6'
    },
    {
      label: 'Birdo 7',
      src: 'https://placekitten.com/700/700',
      value: 'value_7'
    },
    {
      label: 'Birdo 8',
      src: 'https://placekitten.com/800/800',
      value: 'value_8'
    },
    {
      label: 'Birdo 9',
      src: 'https://placekitten.com/900/900',
      value: 'value_9'
    }
  ]

  /** Farbe */
  public selectedColor0 = '#12AD5F'

  /** Farbe */
  public selectedColor1 = 'green'

  /** Farbe */
  public selectedColor2 = '#00ff00'

  /** Farbe */
  public selectedColor3 = '#624EC3'

  /** Farbe */
  public selectedColor4 = '#D8EB9B'

  /** Farbe */
  public selectedColor5 = null

  /** IconPicker Variable */
  public icon1 = 'mdi-airplane'

  /** IconPicker Variable */
  public icon2 = null

  /** IconPicker Variable */
  public icon3 = 'mdi-brain'

  /** IconPicker Variable */
  public icon4 = 'mdi-amazon'
}
