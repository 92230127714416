
import { Component, Prop, Vue } from 'vue-property-decorator'
import GenericError from '@/components/GenericError/GenericError.vue'
import { GenericErrorData } from '@/interfaces/GenericErrorData.interface'

/**
 * Eine `<v-card>`, die zusätzlich einen `<generic-error>` anzeigen kann, wenn
 * das entsprechende Property gesetzt ist. Reicht alle zusätzlichen Attribute
 * und Events unverändert an die `<v-card>` weiter. Somit kann die Komponente
 * als ganz normale `<v-card>` verwendet werden.
 */
@Component({
  components: {
    GenericError
  }
})
export default class ErrorableCard extends Vue {
  /**
   * Anzuzeigender Fehler als [[GenericErrorData]] Fehlerobjekt.
   */
  @Prop({
    type: Object,
    required: false
  })
  public error?: GenericErrorData
}
