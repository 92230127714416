
import Dark from '@/mixins/dark'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import { availableIcons } from './Icons.data'

/**
 * Icon Picker
 */
@Component({
  components: {
    BaseFrame,
    CustomDialog
  }
})
export default class IconPicker extends Mixins(Dark) {
  /**
   * Indikator, ob der Picker angezeigt wird oder nicht.
   */
  public showPicker = false

  /**
   * Anzahl der maximalen Ergebnisse, die pro Ladevorgang nachgeladen werden.
   */
  public maxIcons = 100

  /**
   * Seitenzahl.
   */
  public page = 1

  /**
   * Suchbegriff.
   */
  public searchTerm = ''

  /**
   * Icons, die angezeigt werden.
   */
  public listedIcons: string[] = []

  /**
   * Icons, die abhängig von der Suche angezeigt werden könnten.
   */
  public searchedIcons: string[] = availableIcons

  /**
   * Gewähltes Icon.
   */
  @Prop({ type: String, required: false, default: '' })
  public value?: string

  /**
   * Ob der Name des Icons mit auf dem Button angezeigt wird.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public text!: boolean

  /**
   * Stellt den Button kleiner dar.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public small!: boolean

  /**
   * Eigener Text für den Button, wenn kein Icon gewählt ist.
   */
  @Prop({ type: String, required: false, default: '' })
  public label?: string

  /**
   * Ob der Picker disabled ist.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public disabled!: boolean

  /**
   * Text, der im Button angezeigt wird.
   *
   * @returns Buttontext.
   */
  public get buttonText(): string {
    if (!this.value) {
      return this.label || this.$t('iconPicker.button.selectText').toString()
    }

    return this.value
  }

  /**
   * Sortiert die Liste der verfügbaren Icons so, dass das ausgewählte Icon
   * immer an erster Stelle steht.
   *
   * @returns Liste der Icons.
   */
  public get iconList(): string[] {
    if (this.value) {
      const selectedIconName = this.value.slice(4)

      // Index des gewählten Icons
      const indexSelectedIcon = this.listedIcons.findIndex(
        i => i === selectedIconName
      )

      // Gewähltes Icon als erstes Element hinzufügen
      this.listedIcons.unshift(selectedIconName)

      // Falls das gewählte Element nochmals auftaucht, wird dieses in der Mitte
      // des Arrays entfernt, damit das Icon nicht doppelt vorkommt.
      if (indexSelectedIcon >= 0) {
        this.listedIcons.splice(indexSelectedIcon + 1, 1)
      }
    }
    return this.listedIcons
  }

  /**
   * Selektiert ein Icon.
   *
   * @param icon - Icon
   */
  public selectIcon(icon: string): void {
    this.$emit('input', icon)
    this.searchTerm = ''
    this.showPicker = false
  }

  /**
   * Deselektiert ein Icon und gibt den Wert an die Elternkomponente weiter.
   */
  public resetAndEmit(): void {
    this.$emit('input', null)
  }

  /**
   * Wenn gesucht wird, wird die Seite resettet und die Icons werden
   * entsprechend gefiltert.
   */
  @Watch('searchTerm')
  private resetPageAndSearch(): void {
    this.page = 1
    this.searchedIcons = availableIcons
    this.listedIcons = []
    if (this.searchTerm) {
      this.searchedIcons = this.searchedIcons.filter(
        f => f.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0
      )
    }
    this.addNewIcons()
  }

  /**
   * Reagiert auf Scrollen.
   *
   * @param scrollTop - Die Distanz, die nach unten gescrollt wurde
   * @param scrollHeight - Die Distanz, maximal gescrollt werden kann
   * @param clientHeight - Die Größe des sichtbaren Bereichs
   */
  public scrollEventHandler(
    scrollTop: number,
    scrollHeight: number,
    clientHeight: number
  ): void {
    const scrollTopMax = scrollHeight - clientHeight

    const scrollTopCalc: number = scrollTop + 500

    // Wenn Wert überschritten wird, dann addiere eine Zahl zu den Seiten.
    if (scrollTopCalc > scrollTopMax) {
      this.page++
      this.addNewIcons()
    }
  }

  /**
   * Sucht die nächsten Icons, die angezeigt werden sollen und fügt diese dem
   * Array hinzu.
   */
  public addNewIcons(): void {
    if (
      this.maxIcons * this.page > this.listedIcons.length &&
      this.listedIcons.length !== this.searchedIcons.length
    ) {
      const newIcons = this.searchedIcons.slice(
        this.listedIcons.length,
        this.maxIcons * this.page
      )

      // Array erweitern.
      this.listedIcons = [...new Set([...this.listedIcons, ...newIcons])]
    }
  }

  /**
   * Created-Funktion. Befüllt das Array mit den ersten Icons.
   */
  public created(): void {
    this.listedIcons = this.searchedIcons.slice(0, this.maxIcons)
  }
}
