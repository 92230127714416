
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

/**
 * Komponente für das Anzeigen eines Suchfeldes.
 */
@Component({
  components: {}
})
export default class SearchField extends Vue {
  /**
   * Der Suchstring.
   */
  @Prop({ type: String, required: true, default: '' })
  public value!: string

  /**
   * Interne Eigenschaft mit dem Suchstring.
   */
  public searchText = ''

  /**
   * Der Sprachschlüssel der für die Beschriftung des Suchfeldes verwendet wird.
   */
  @Prop({ type: String, required: false, default: 'system.general.search' })
  public labelLanguageKey!: string

  /**
   * Aktiviert den Autofokus.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public autofocus!: boolean

  /**
   * Ob eine Ladeanimation angezeigt werden soll. Als Ladeanimation wird die
   * 'Process bar'-Komponente verwendet.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public loading!: boolean

  /**
   * Aktiviert des Lesemodus für die Suche.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly!: boolean

  /**
   * Entsprechender Wert, der als Platzhalter verwendet wird.
   */
  @Prop({ type: String, required: false })
  public placeholder?: string

  /**
   * Hängt ein Icon innerhalb des Textfeld an.
   */
  @Prop({ type: String, required: false })
  public appendIcon?: string

  /**
   * Hängt ein Icon außerhalb des Textfeld an.
   */
  @Prop({ type: String, required: false })
  public appendOuterIcon?: string

  /**
   * Stellt ein Icon außerhalb des Textfeld voran.
   */
  @Prop({ type: String, required: false })
  public prependIcon?: string

  /**
   * Stellt ein Icon innerhalb des Textfeld voran.
   */
  @Prop({ type: String, required: false })
  public prependInnerIcon?: string

  /**
   * Die ID des letzten gestarteten Timeouts.
   */
  public timeoutID?: number

  /**
   * Gibt den Suchstring vom Property [[this.value]] an die Eigenschaft
   * [[this.searchText]] weiter, wenn die Komponente erstellt wird.
   */
  public created(): void {
    this.searchText = this.value
  }

  /**
   * Reicht das Click-Event der Icons vom Textfeld weiter.
   *
   * @param name - Füft ein Name an das Event an.
   */
  public triggerClickEvent(name?: string): void {
    if (!this.readonly) {
      let event = 'click'

      if (typeof name === 'string') {
        const eventName = name.trim().toLowerCase()

        if (eventName !== '') {
          event = `${event}:${eventName}`
        }
      }

      this.$emit(event)
    }
  }

  /**
   * Prüft ob noch ein aktiver Timeout vorhanden ist. Ist dies dies der Fall,
   * wird der vorhandene dieser
   * beendet und seine ID gelöscht.
   */
  public clearTimeout(): void {
    if (this.timeoutID) {
      window.clearTimeout(this.timeoutID)
      delete this.timeoutID
    }
  }

  /**
   * Leerst/Entfernt den aktuellen Suchstring.
   */
  public clearValue(): void {
    this.clearTimeout()
    this.searchText = ''
    this.$emit('input', '')
  }

  /**
   * Überwacht die Eigenschaft [[this.searchText]] und gibt diesen Wert an die
   * Property [[this.value]] weiter.
   *
   * @param value -Der neue Suchstring.
   */
  @Watch('searchText')
  public watcherSearchText(value: string): void {
    this.clearTimeout()

    const newValue = value.trim()
    const publicValue = (this.value || '').trim()

    if (newValue !== publicValue) {
      this.timeoutID = window.setTimeout(
        (search: string): void => {
          this.$emit('input', search)
        },
        550,
        newValue
      )
    }
  }

  /**
   * Überwacht die Property [[this.value]] und gibt diesen Wert an die
   * Eigenschaft [[this.searchText]] weiter.
   *
   * @param value -Der neue Suchstring.
   */
  @Watch('value')
  public watcherValue(value: string): void {
    this.clearTimeout()

    const publicValue = value.trim()
    const newValue = (this.searchText || '').trim()

    if (newValue !== publicValue) {
      this.searchText = publicValue
    }
  }
}
